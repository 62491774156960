import React from 'react'
import { Slide, ToastContainer } from 'react-toastify'
import Header from './Header'
import { Switch, Route, Link } from 'react-router-dom'
import routes from '../../routes'
import {
    MDBContainer,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBCard,
} from 'mdbreact'
import { toast } from 'react-toastify'
import { getEmail, getCurrentProject } from '../../store/Main/reducer'
import {
    MAIN_EMAIL_LOAD_END,
    MAIN_SET_CURRENT_PROJECT,
} from '../../store/Main/actionTypes'
import { connect } from 'react-redux'

import clicker from '../../assets/images/clicker.png'
import autochess from '../../assets/images/autochess.png'
import ac from '../../assets/images/ac.png'

class Main extends React.Component {
    componentDidMount() {
        const { projectName } = this.props.match.params
        const email = localStorage.getItem('email')

        if (email) {
            this.props.setEmailData(email)
        }
        if (projectName) {
            this.props.setCurrentProject(projectName)
        }

        window.addEventListener('message', this.authIframeListener)
    }

    componentDidUpdate(prevProps) {
        const { projectName } = this.props.match.params
        const { setCurrentProject } = this.props
        if (
            projectName &&
            projectName !== prevProps.match.params.projectName &&
            ['clicker', 'autochess', 'harem', 'mp', "bw", "aw", "ac"].includes(
                projectName
            )
        ) {
            setCurrentProject(projectName)
        }
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.authIframeListener)
    }

    authIframeListener = (event) => {
        if (event.data) {
            if (event.origin !== 'https://auth-service.playful-fairies.com') {
                return
            }
            if (event.data.type === 'success') {
                if (event.data.token && event.data.email) {
                    localStorage.setItem('accessToken', event.data.token)
                    localStorage.setItem('email', event.data.email)
                    this.props.setEmailData(event.data.email)
                }
            } else if (event.data.type === 'error') {
                toast.error('Login error. Please try again', {
                    autoClose: 3000,
                })
            }
        }
    }

    setImageForBackground = (project) => {
        let image
        switch (project) {
            case 'clicker':
                image = clicker
                break
            case 'autochess':
                image = autochess
                break
            case 'harem':
                break
            case 'mp':
                break
            case 'aw':
                break
            case 'bw':
                break
            case 'ac':
                image = ac
                break
            default:
                return
        }

        return `url(${image})`
    }

    render() {
        return (
            <React.Fragment>
                <ToastContainer
                    transition={Slide}
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeButton={false}
                />
                <Header />
                <MDBContainer className="content">
                    <div
                        className="bgImageMain"
                        style={{
                            backgroundImage:
                                this.props.match.url !== '/'
                                    ? this.setImageForBackground(
                                          this.props.currentProject
                                      )
                                    : null,
                        }}
                    />

                    {!this.props.email && (
                        <MDBModal isOpen={true} size="sm" toggle={() => null}>
                            <MDBModalHeader className="d-flex justify-content-center align-items-center">
                                You are unauthorized
                            </MDBModalHeader>
                            <MDBModalBody className="d-flex justify-content-center">
                                <iframe
                                    align="middle"
                                    title="authWindow"
                                    width="220"
                                    height="60"
                                    frameBorder="no"
                                    scrolling="no"
                                    marginWidth="10"
                                    marginHeight="10"
                                    src="https://auth-service.playful-fairies.com/auth.html"
                                />
                            </MDBModalBody>
                        </MDBModal>
                    )}

                    {!this.props.match.params.projectName &&
                        !this.props.match.params.pageName && (
                            <div className="projectsContainer">
                                {[
                                    'clicker',
                                    'autochess',
                                    'harem',
                                    'mp',
                                    'aw',
                                    'bw',
                                    'ac',
                                ].map((project) => (
                                    <MDBCard
                                        key={project}
                                        className="projectItem"
                                    >
                                        <Link
                                            to={`/${project}/in-game-tickets`}
                                        >
                                            <div className="projectName">
                                                {project}
                                            </div>
                                            <div
                                                className="projectImage"
                                                style={{
                                                    backgroundImage: this.setImageForBackground(
                                                        project
                                                    ),
                                                }}
                                            />
                                        </Link>
                                    </MDBCard>
                                ))}
                            </div>
                        )}

                    <Switch>
                        {routes.map((route, index) => {
                            return route.component ? (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={(props) => (
                                        <route.component {...props} />
                                    )}
                                />
                            ) : null
                        })}
                    </Switch>
                </MDBContainer>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        email: getEmail(state),
        currentProject: getCurrentProject(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setEmailData: (email) => dispatch({ type: MAIN_EMAIL_LOAD_END, email }),
        setCurrentProject: (project) =>
            dispatch({ type: MAIN_SET_CURRENT_PROJECT, project }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Main)
