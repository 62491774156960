import React from 'react'
import {
    Navbar,
    NavbarBrand,
    NavbarNav,
    NavItem,
    NavbarToggler,
    Collapse,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavLink,
} from 'mdbreact'
import logo from '../../assets/images/logo.jpg'
import logoClicker from '../../assets/images/logo.jpg'
import { CircularProgress } from '@material-ui/core'
import { getEmail, getCurrentProject } from '../../store/Main/reducer'
import {
    MAIN_EMAIL_LOAD_END,
    MAIN_SET_CURRENT_PROJECT,
} from '../../store/Main/actionTypes'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

class Header extends React.Component {
    state = {
        isOpen: false,
    }

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    onLoginout = () => {
        localStorage.setItem('email', '')
        localStorage.setItem('accessToken', '')
        this.props.setEmailData('')
    }

    setHeaderColor = (project) => {
        switch (project) {
            case 'harem':
                return 'default-color-dark'
            case 'mp':
                return 'info-color-dark'
            case 'autochess':
                return 'stylish-color-dark'
            case 'clicker':
                return 'secondary-color-dark'
            case 'ac':
                return 'secondary-color-dark'
            default:
                return 'unique-color-dark'
        }
    }

    setImageForLogo = (project) => {
        let image = null
        switch (project) {
            case 'clicker':
                image = logoClicker
                break
            default:
                image = logo
                break
        }

        return image
    }

    render() {
        return (
            <Navbar
                dark
                expand="md"
                className="sticky-top"
                color={this.setHeaderColor(this.props.match.params.projectName)}
            >
                <NavbarBrand className="p-0" href="/">
                    <img
                        src={this.setImageForLogo(
                            this.props.match.params.projectName
                        )}
                        style={{ height: 50, borderRadius: 5 }}
                        alt="Brand"
                    />
                </NavbarBrand>
                <NavbarToggler onClick={this.toggleCollapse} />
                <Collapse isOpen={this.state.isOpen} navbar>
                    {this.props.match.url !== '/' && (
                        <NavbarNav left>
                            <NavItem>
                                <NavLink
                                    to={`/${this.props.currentProject}/in-game-tickets`}
                                >
                                    <i className="fas fa-ticket-alt" /> In-game
                                    Tickets
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={`/${this.props.currentProject}/site-tickets`}
                                >
                                    <i className="fas fa-ticket-alt" /> Site
                                    Tickets
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={`/${this.props.currentProject}/manage-quick-answers`}
                                >
                                    <i className="fas fa-bolt" /> Manage quick
                                    answers
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    to={`/${this.props.currentProject}/constants`}
                                >
                                    <i className="fab fa-cuttlefish" />{' '}
                                    Constants
                                </NavLink>
                            </NavItem>
                        </NavbarNav>
                    )}

                    <NavbarNav right>
                        {this.props.match.url !== '/' && (
                            <>
                                <NavItem>
                                    <div className="headerProject">
                                        {this.props.currentProject}
                                    </div>
                                </NavItem>
                            </>
                        )}

                        <Dropdown>
                            <DropdownToggle nav caret className="white-text">
                                {this.props.email !== null ? (
                                    this.props.email
                                ) : (
                                    <CircularProgress
                                        variant="indeterminate"
                                        style={{ color: '#fff' }}
                                        size={20}
                                        thickness={5}
                                    />
                                )}
                            </DropdownToggle>
                            {this.props.email && (
                                <DropdownMenu>
                                    <DropdownItem onClick={this.onLoginout}>
                                        Logout
                                    </DropdownItem>
                                </DropdownMenu>
                            )}
                        </Dropdown>
                    </NavbarNav>
                </Collapse>
            </Navbar>
        )
    }
}

function mapStateToProps(state) {
    return {
        email: getEmail(state),
        currentProject: getCurrentProject(state),
    }
}

function mapDispatchToProps(dispatch) {
    return {
        setEmailData: (email) => dispatch({ type: MAIN_EMAIL_LOAD_END, email }),
        setCurrentProject: (project) =>
            dispatch({ type: MAIN_SET_CURRENT_PROJECT, project }),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header))
